<template>
  <th-wrapper :title="$t('pages.voucher_systems.form.external_system.label')">
    <!-- Enabled -->
    <el-switch v-model="externalSystemEnabled" :active-text="switchMessage" />
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  name: 'ExternalSystem',

  computed: {
    externalSystemEnabled: {
      get() {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.vouchers.use_external'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.vouchers.use_external',
          value: newValue
        })
      }
    },

    switchMessage() {
      return this.externalSystemEnabled
        ? this.$t('common.forms.switch.label_enabled')
        : this.$t('common.forms.switch.label_disabled')
    }
  }
}
</script>
