<template>
  <th-wrapper :title="$t('nav.main.items.loyalty.voucher_systems')">
    <!-- Enabled -->
    <el-switch v-model="voucherSystemEnabled" :active-text="switchMessage" />
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  name: 'VoucherSystem',

  computed: {
    voucherSystemEnabled: {
      get() {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'vouchers.enabled'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'vouchers.enabled',
          value: newValue
        })
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.voucher_systems_source',
          value: 'voucher_systems'
        })
      }
    },

    switchMessage() {
      return this.voucherSystemEnabled
        ? this.$t('common.forms.switch.label_enabled')
        : this.$t('common.forms.switch.label_disabled')
    }
  }
}
</script>
