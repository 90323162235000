<template>
  <div>
    <voucher-system />
    <external-system />
  </div>
</template>

<script>
import VoucherSystem from './components/voucher-system'
import ExternalSystem from './components/external-system'

export default {
  components: {
    VoucherSystem,
    ExternalSystem
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.voucher-systems.title')
    }
  }
}
</script>
